const Request = require("request");
const axios = require("axios");

export const GetSedes = () => {
  return new Promise((resolve, reject) => {
    Request.get(
      "https://api.artefrances.com/sedes",
      (error, response, body) => {
        if (error) {
          console.log(error);
          reject(error);
        }
        resolve(JSON.parse(body));
      }
    );
  });
};

export const GetOffers = () => {
  return new Promise((resolve, reject) => {
    Request.get(
      "https://api.artefrances.com/ofertas",
      (error, response, body) => {
        if (error) {
          console.log(error);
          reject(error);
        }
        resolve(JSON.parse(body));
      }
    );
  });
};

export const SetNewUser = (user) => {

  return new Promise((resolve, reject) => {
    axios
      .post("https://api.artefrances.com/Clientes", {
        NombreCompleto: user.fullname,
        Correo: user.email,
        Celular: user.phone,
        Promocion: user.promotion,
        Sede:user.sede,
        TipoDeContacto:user.contactType,

      })
      .then((res) => {
        resolve();
   
      })
      .catch((error) => {
        console.error(error);
        reject(error);
      });
  });
};

export const GetUsers = () => {
  return new Promise((resolve, reject) => {
    Request.get(
      "https://api.artefrances.com/clientes?_limit=-1",
      (error, response, body) => {
        if (error) {
          console.log(error);
          reject(error);
        }
        resolve(JSON.parse(body));
      }
    );
  });
};
