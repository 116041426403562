import React from "react";
import Phone from "../components/phone";
import Whatsapp from "../components/whatsapp";
import "../utils/styles/sede.css";
export const Sede = ({title, time,onClickPhone,onClickWhatsapp}) => {

  
  return (
    <div>
      <h4 className="title"> {title}</h4>
      <p className="time" >{time}</p>
      <div>
        <button className="btn  c mr-3" onClick={() => { onClickPhone()}}>Llamar <Phone className="ml-3" fill="#113B81" width="24"> </Phone> </button>
        <button className="btn w" onClick={onClickWhatsapp} >Whatsapp<Whatsapp  className="ml-3" fill="#22e010" width="24"> </Whatsapp> </button>
      </div>
    </div>
  );
};

export default Sede;
