import React from "react";

import * as validate from "../utils/tools/formValidator";
import "../utils/styles/register.css";
import Logo from "../assets/logo.png";

import Carousel from "react-elastic-carousel";
import Offer from "../components/offer";
import Oferta from "../components/Oferta";
import Sede from "../components/sedes";
import { GetSedes, GetOffers, SetNewUser, GetUsers } from "../services/cms";
import "react-responsive-modal/styles.css";
import { Modal } from "react-responsive-modal";
import ButtonArrow from "../components/buttonArrow";
import Facebook from "../components/facebook";
import Instagram from "../components/instagram";
import TermC from "../assets/Politica_de_Tratamiento_de_Datos_BELPRO_SAS.pdf";
export default function Register() {
 
  const [email, setEmail] = React.useState({ value: "", error: "" });
  const [fullName, setFullName] = React.useState({ value: "", error: "" });
  const [number, setNumber] = React.useState({ value: "", error: "" });
  const [promotion, setPromotion] = React.useState(false);
  const [conditions, setConditions] = React.useState(false);

  const [ofertaAplicada, setOfertaAplicada] = React.useState("Ninguna");
  const [offerts, setOfferts] = React.useState([]);
  const [sedes, setSedes] = React.useState([]);
 
  const [options, setOptions] = React.useState({
    sede: {},
    mode: 0,
    modal: false,
  });

  React.useEffect(() => {
    LoadData();
  }, []);

  const CheckedTConditions = (e) => {
    setConditions(e.target.checked);
  };

  const CheckedPromotions = (e) => {
    setPromotion(e.target.checked);
  };

  const LoadData = () => {
    GetSedes()
      .then((res) => {
        setSedes(res);
      
      })
      .catch((err) => {
        console.log(err);
      });

    GetOffers()
      .then((res) => {
        setOfferts(res);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    const emailError = validate.emailValidator(email.value);
    const fullNameError = validate.nameValidator(fullName.value);
    const numberError = validate.numberValidator(number.value);

    if (emailError || numberError || fullNameError) {
      setEmail({ ...email, error: emailError });
      setFullName({ ...fullName, error: fullNameError });
      setNumber({ ...number, error: numberError });

      return;
    }
    GetUsers().then((users) => {
      let valid = false;

      for (let i = 0; i < users.length; i++) {
        if (users[i].Correo === email.value) {
        
          valid = true;

          setOptions({ modal: false });
          alert("El correo ya existe, sera redireccionado a automaticamente.");

          if (options.mode === 0) {
            window.open("tel:" + options.sede.Telefono);
          } else if (options.mode === 1) {
            window.open(
              `https://api.whatsapp.com/send/?phone=${options.sede.LinkWhatsapp}&text=¡Hola! Quiero reservar una cita soy : ${fullName.value} con esta oferta : ${ofertaAplicada}`,
              "_blank"
            );
          }
          break;
        }

        if (i === users.length - 1) {
          
          if (valid === false) {
            SetNewUser({
              fullname: fullName.value,
              email: email.value,
              phone: number.value,
              promotion: promotion,
              sede: options.sede.Titulo,
              contactType: options.mode == 0 ? "Telefono" : "Whatsapp",
            })
              .then(() => {
                if (options.mode == 0) {
                  window.open("tel:" + options.sede.Telefono);
                  setOptions({ modal: false });
                } else if (options.mode == 1) {
                  window.open(
                    `https://api.whatsapp.com/send/?phone=${options.sede.LinkWhatsapp}&text=¡Hola! Quiero reservar una cita soy : ${fullName.value} con esta oferta : ${ofertaAplicada}`,
                    "_blank"
                  );

                  setOptions({ modal: false });
                }
              })
              .catch((err) => {
                alert(
                  "Ocurrio un error por favor comunicarse con soporte :" + err
                );
              });
          }
        }
      }
    });
  };

  return (
    <>
      <div className=" reg-container ">
        <div>
          <img src={Logo} alt="logo artes frances" className="logo"></img>
          <Carousel>
            {offerts.map((item) => (
              <div className="col-12">
                <Oferta
                  url={"https://api.artefrances.com" + item.Imagen[0].url}
                  title={item.Titulo}
                  description={item.Descripcion}
                  onClick={() => {
                    setOfertaAplicada(item.Titulo);
                  }}
                ></Oferta>
              </div>
            ))}
          </Carousel>
          <hr></hr>
          <div className="row col-12 align-items-center">
            <p className="font-weight-bold  m-0  ">
              <Offer width="25" /> Oferta seleccionada :
              {ofertaAplicada == "" ? (
                <span className="font-weight-normal">Ninguna</span>
              ) : (
                <span className="font-weight-normal">{ofertaAplicada}</span>
              )}
            </p>
          </div>

          <h3 className="title my-3">SEDE</h3>
          <p className="font-weight-normal">
            Desde aqui o por nuestras redes sociales puedes solicitar una cita
            en la sede que mas te guste!
          </p>
          <div>
            {sedes.map((sede, i) => (
              <div key={i}>
                <Sede
                  title={sede.Titulo}
                  time={sede.Horario}
                  onClickPhone={() => {
                    setOptions({ sede: sede, mode: 0, modal: true });
                  }}
                  onClickWhatsapp={() => {
                    setOptions({ sede: sede, mode: 1, modal: true });
                  }}
                ></Sede>
                <hr className="my-3"></hr>
              </div>
            ))}
          </div>
        </div>
      </div>
      <div className="footer ">
        <div>
          <a href="tel:(2)6684445" className="phone">
            PBX (2)6684445
          </a>
          <a href="mailto:servicioalcliente@artefrances.com" className="email">
            servicioalcliente@artefrances.com
          </a>
        </div>
        <div className="d-flex">
          <div>
            <a
              href="https://www.facebook.com/ArteFrancesCentrodeBelleza"
              rel="noreferrer"
              target="_blank"
              className="social mx-2"
            >
              <Facebook width="35" />
            </a>
          </div>
          <div>
            <a
              href="https://www.instagram.com/artefrancescentrodebelleza/?hl=es"
              rel="noreferrer"
              target="_blank"
              className="social  mx-2"
            >
              <Instagram width="35" />
            </a>
          </div>
        </div>
      </div>

      <Modal
        open={options.modal}
        onClose={() => {
          setOptions({ modal: false });
          return true;
        }}
      >
        <h2 className="modalT">FORMULARIO</h2>
        <p className="modalP">
          Diligencia los datos para conectarlo con la sede
        </p>
        <form className="form mt-5">
          <div class="form-group">
            <label for="name">NOMBRE COMPLETO</label>
            <input
              type="text"
              class="form-control"
              onChange={(event) =>
                setFullName({ value: event.target.value, error: "" })
              }
            />
            <small className="text-danger">{fullName.error}</small>
          </div>
          <div class="form-group">
            <label for="email">CORREO ELECTRONICO</label>
            <input
              type="email"
              class="form-control"
              onChange={(event) =>
                setEmail({ value: event.target.value, error: "" })
              }
            />
            <small className="text-danger">{email.error}</small>
          </div>
          <div class="form-group">
            <label for="phone">CELULAR</label>
            <input
              type="number"
              class="form-control"
              onChange={(event) =>
                setNumber({ value: event.target.value, error: "" })
              }
            />
            <small className="text-danger">{number.error}</small>
          </div>
          <div class="form-check mt-4">
            <input
              class="form-check-input"
              type="checkbox"
              name="conditions"
              id="defaultCheck1"
              value={conditions}
              defaultChecked={conditions}
              onChange={(e) => CheckedTConditions(e)}
            />
            <label class="form-check-label" for="defaultCheck1">
              ACEPTAR TERMINOS Y CONDICIONES,
              <a href={TermC} target="_blank" rel="noopener noreferrer">
                VER MAS
              </a>
            </label>
          </div>
          <div class="form-check mt-2">
            <input
              class="form-check-input"
              type="checkbox"
              name="promotion"
              value={promotion}
              onChange={(e) => CheckedPromotions(e)}
              defaultChecked={promotion}
              id="defaultCheck1"
            />
            <label class="form-check-label" for="defaultCheck1">
              RECIBIR PROMOCIONES
            </label>
          </div>

          <a
            onClick={handleSubmit}
            className={`btn col-12 send ${conditions ? "" : "disabled"}  `}
          >
            {options.mode == 1 ? "Enviar Whatsapp" : "Llamar"} <ButtonArrow />
          </a>
        </form>
      </Modal>
    </>
  );
}
