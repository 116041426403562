export const emailValidator = (email) => {
  const re = /\S+@\S+\.\S+/;

  if (!email || email.length <= 0) return "Debe ingresar un email";
  if (!re.test(email)) return "Ooops! ingresa un email valido";
  return "";
};


export const nameValidator = (name) => {
  if (!name || name.length <= 0) return "Debe ingresar un nombre completo";
  return "";
};

export const numberValidator = (cc) => {
  if (cc.length <= 0) return "Debe de ingresar un numero de contacto";
  return "";
};

export const medioValidator = (user, type) => {
  if (user.length <= 0) return "Debe de ingresar medio de comunicación";

  return "";
};
