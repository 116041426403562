import * as React from "react"

function Offert(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 512.003 512.003"
      {...props}
    >
      <path d="M477.958 262.633a15.004 15.004 0 010-13.263l19.096-39.065c10.632-21.751 2.208-47.676-19.178-59.023l-38.41-20.38a15.005 15.005 0 01-7.796-10.729l-7.512-42.829c-4.183-23.846-26.241-39.87-50.208-36.479l-43.053 6.09a15.004 15.004 0 01-12.613-4.099l-31.251-30.232c-17.401-16.834-44.661-16.835-62.061 0L193.72 42.859a15.01 15.01 0 01-12.613 4.099l-43.053-6.09c-23.975-3.393-46.025 12.633-50.208 36.479l-7.512 42.827a15.008 15.008 0 01-7.795 10.73l-38.41 20.38c-21.386 11.346-29.81 37.273-19.178 59.024l19.095 39.064a15.004 15.004 0 010 13.263L14.95 301.699c-10.632 21.751-2.208 47.676 19.178 59.023l38.41 20.38a15.005 15.005 0 017.796 10.729l7.512 42.829c3.808 21.708 22.422 36.932 43.815 36.93 2.107 0 4.245-.148 6.394-.452l43.053-6.09a15 15 0 0112.613 4.099l31.251 30.232c8.702 8.418 19.864 12.626 31.03 12.625 11.163-.001 22.332-4.209 31.03-12.625l31.252-30.232c3.372-3.261 7.968-4.751 12.613-4.099l43.053 6.09c23.978 3.392 46.025-12.633 50.208-36.479l7.513-42.827a15.008 15.008 0 017.795-10.73l38.41-20.38c21.386-11.346 29.81-37.273 19.178-59.024l-19.096-39.065zM196.941 123.116c29.852 0 54.139 24.287 54.139 54.139s-24.287 54.139-54.139 54.139-54.139-24.287-54.139-54.139 24.287-54.139 54.139-54.139zm-27.944 240.77c-2.883 2.883-6.662 4.325-10.44 4.325s-7.558-1.441-10.44-4.325c-5.766-5.766-5.766-15.115 0-20.881l194.889-194.889c5.765-5.766 15.115-5.766 20.881 0s5.766 15.115 0 20.881l-194.89 194.889zm146.064 25.002c-29.852 0-54.139-24.287-54.139-54.139s24.287-54.139 54.139-54.139c29.852 0 54.139 24.287 54.139 54.139s-24.287 54.139-54.139 54.139z" />
      <path d="M315.061 310.141c-13.569 0-24.609 11.039-24.609 24.608s11.039 24.608 24.609 24.608c13.569 0 24.608-11.039 24.608-24.608s-11.039-24.608-24.608-24.608zM196.941 152.646c-13.569 0-24.608 11.039-24.608 24.608s11.039 24.609 24.608 24.609 24.609-11.039 24.609-24.609c-.001-13.568-11.04-24.608-24.609-24.608z" />
    </svg>
  )
}

export default Offert
