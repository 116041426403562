import * as React from "react"

function Facebook(props) {
  return (
    <svg
     
      viewBox="0 0 25 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M13.955 21.872v-8.538h2.88l.429-3.342h-3.309v-2.13c0-.964.27-1.624 1.653-1.624h1.755v-2.98a23.266 23.266 0 00-2.57-.133c-2.546 0-4.294 1.554-4.294 4.407v2.453H7.638v3.343h2.867v8.544h3.45z"
        fill="#fff"
      />
    </svg>
  )
}

export default Facebook
