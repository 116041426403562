import React from 'react'
import "../utils/styles/offerts.css";
import Arrow from "./arrow"
export const Oferta = ({url,title,description, onClick}) => {
    return (
        <div className="ofContainer">

       
        <div className="info" style={{backgroundImage:`url(${url})`}}>
            <h3>{title}</h3>
            <p>{description}</p>
            <button onClick={onClick} class="btnOffert">Aplicar Oferta</button>
        </div>
        </div>
    )
}
export default Oferta;