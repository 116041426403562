import * as React from "react"

function Instagram(props) {
  return (
    <svg
  
      viewBox="0 0 25 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M21.82 8.651a6.801 6.801 0 00-.436-2.308A4.801 4.801 0 0018.64 3.6a6.881 6.881 0 00-2.277-.437c-1.002-.045-1.32-.058-3.864-.058-2.544 0-2.87 0-3.864.058A6.881 6.881 0 006.36 3.6a4.799 4.799 0 00-2.743 2.743 6.827 6.827 0 00-.437 2.276c-.044 1.003-.058 1.32-.058 3.864 0 2.544 0 2.87.058 3.865.016.78.163 1.548.437 2.278a4.802 4.802 0 002.744 2.742 6.86 6.86 0 002.276.469c1.003.044 1.32.058 3.864.058 2.544 0 2.87 0 3.865-.058a6.865 6.865 0 002.277-.437 4.809 4.809 0 002.743-2.743c.274-.729.42-1.498.436-2.278.045-1.002.058-1.32.058-3.864-.002-2.544-.002-2.867-.06-3.864zm-9.326 8.643a4.815 4.815 0 01-4.816-4.816 4.815 4.815 0 119.632 0 4.816 4.816 0 01-4.816 4.816zM17.5 8.607a1.121 1.121 0 110-2.245 1.122 1.122 0 010 2.245z"
        fill="#fff"
      />
      <path
        d="M12.494 15.606a3.128 3.128 0 100-6.256 3.128 3.128 0 000 6.256z"
        fill="#fff"
      />
    </svg>
  )
}

export default Instagram
