import React from "react";
import ReactDOM from "react-dom";
import "./utils/styles/index.css";
import 'bootswatch/dist/lux/bootstrap.min.css';
import reportWebVitals from "./reportWebVitals";
import Register from "./screens/register";
import { HashRouter as Router, Route } from "react-router-dom";
import { spring, AnimatedRoute , AnimatedSwitch} from 'react-router-transition';

function mapStyles(styles) {
  return {
    opacity: styles.opacity,
    transform: `scale(${styles.scale})`,
  };
}

function bounce(val) {
  return spring(val, {
    stiffness: 330,
    damping: 22,
  });
}

const bounceTransition = {
  atEnter: {
    opacity: 0,
    scale: 1.2,
  },
  atLeave: {
    opacity: bounce(0),
    scale: bounce(0.8),
  },
  atActive: {
    opacity: bounce(1),
    scale: bounce(1),
  },
};

ReactDOM.render(
  <Router>
    <div>
      <main>
        <AnimatedSwitch
          atEnter={bounceTransition.atEnter}
          atLeave={bounceTransition.atLeave}
          atActive={bounceTransition.atActive}
         
          className="route-wrapper"
        >
          <Route exact path="/" component={Register} />
        </AnimatedSwitch>
      </main>
    </div>
  </Router>,
  document.getElementById("root")
);

reportWebVitals();
