import * as React from "react"

function ButtonArrow(props) {
  return (
    <svg
      width={75}
      height={15}
      viewBox="0 0 75 15"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M74.707 8.144a1 1 0 000-1.415L68.343.365A1 1 0 1066.93 1.78l5.657 5.656-5.657 5.657a1 1 0 001.414 1.415l6.364-6.364zM0 8.436h74v-2H0v2z"
        fill="#fff"
      />
    </svg>
  )
}

export default ButtonArrow
